// This file should contain basic types for communicating with the GDSDX business microservices.
// Types and functions used internally in the web frontend, which can be modified without affecting compatibility with the backend, should be placed in utils/gdsdx.

// Result of an ajax call to the gdsdx business microservices
// NOTE: Since we are operating on existing objects, not objects of a specific class that we created ourselves, we should use type/interface instead of class here.
export interface Res<T> {
  success: boolean;
  code: number;
  message: string;
  timestamp: number;
  data: T; // could actually be null (from JSON null) or undefined when success is false, but we don't care.  T might also be nullable itself.
}

// Common business types appearing in the JSON API
export type TimeRange = {
  t0: number | undefined;
  t1: number | undefined;
}
export type LocalDateRange = {
  startD: string | undefined;
  endD: string | undefined;
}

export interface SocWorkcode {
  socCode: number;
  wc: string;
}
export type SocWorkcodeSpec = {
  socCode: number;
  wc: string;
}
export interface DspAssetIdV {
  dspId: number;
  assetId: string;
}
export type DspAssetIdSpec = {
  dspId: number;
  assetId: string;
}

export const enum XSocCodes { NONE = -1, TOTAL = -2 } // an socCodex can be an ordinary socCode or a special value here
export interface SocInfo {
  socCode: number;
  name: string;
}

export const enum XDspIds { NONE = -1, MIXED = -2 } // an xDspId can be an ordinary dspId or a special value here
export interface DspInfo {
  dspId: number;
  dspName: string;
}
export interface DspSvcInfo {
  svcId: number;
  dspId: number;
  svcName: string;
}
export interface SetupRsp {
  socs: SocInfo[];
  dsps: DspInfo[];
  dspSvcs: DspSvcInfo[];
}

export interface DlLinkRsp {
  dlToken: string;
}

export const enum AccFilterMode { ENFORCING = 1, PERMISSIVE = 2, DISABLED = 3 }
export type AccFilterSoc = {
  socCode: number;
  filterMode: AccFilterMode;
};
export const enum AccFilterResult { ALLOW = "ALLOW", DENY = "DENY" }
export interface CreatorV {
  uid: string;
  userName?: string | null;
  asocCode: number;
}
export interface EditInfoV {
  uid: string;
  userName?: string | null;
  tEdit: number;
}

export const enum TerriSpecType { INCLUDE = "include", EXCLUDE = "exclude" }
export interface TerriSpecV {
  type: TerriSpecType;
  tcodes: string[];
}
export type TerriSpecSpec = {
  type: TerriSpecType;
  tcodes: string[];
}

export interface GdsdxJobExecV {
  jxId: number;
  isDone: boolean;
  isOk?: boolean | null;
  msg?: string | null;
}
export interface StatusRspBase {
  jx?: GdsdxJobExecV | null;
}
export interface StatusRspWithPreview extends StatusRspBase {
  hasPreview: boolean;
}