var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-modal', _vm._g(_vm._b({
    ref: "modal",
    class: _vm.getClass(_vm.modalClass),
    style: _vm.getStyle(_vm.modalStyle),
    attrs: {
      "visible": _vm.visible,
      "destroyOnClose": ""
    },
    on: {
      "ok": _vm.handleOk,
      "cancel": _vm.handleCancel
    },
    scopedSlots: _vm._u([_vm._l(_vm.slotsKeys, function (slotName) {
      return {
        key: slotName,
        fn: function fn() {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'a-modal', _vm._attrs, false), _vm.$listeners), [_vm._t("default"), !_vm.isNoTitle ? _c('template', {
    slot: "title"
  }, [_c('a-row', {
    staticClass: "j-modal-title-row",
    attrs: {
      "type": "flex"
    }
  }, [_c('a-col', {
    staticClass: "left"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2), _vm.switchFullscreen ? _c('a-col', {
    staticClass: "right",
    on: {
      "click": _vm.toggleFullscreen
    }
  }, [_c('a-button', {
    staticClass: "ant-modal-close ant-modal-close-x",
    attrs: {
      "ghost": "",
      "type": "link",
      "icon": _vm.fullscreenButtonIcon
    }
  })], 1) : _vm._e()], 1)], 1) : _c('template', {
    slot: "title"
  }, [_c('a-row', {
    staticClass: "j-modal-title-row",
    attrs: {
      "type": "flex"
    }
  }, [_vm.switchFullscreen ? _c('a-col', {
    staticClass: "right",
    on: {
      "click": _vm.toggleFullscreen
    }
  }, [_c('a-button', {
    staticClass: "ant-modal-close ant-modal-close-x",
    attrs: {
      "ghost": "",
      "type": "link",
      "icon": _vm.fullscreenButtonIcon
    }
  })], 1) : _vm._e()], 1)], 1), _vm._l(_vm.scopedSlotsKeys, function (slotName) {
    return _c('template', {
      slot: slotName
    }, [_vm._t(slotName)], 2);
  })], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };