import Vue, { PluginObject } from 'vue'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import router from '@/router/index'
import { ACCESS_TOKEN, TENANT_ID } from "@/store/mutation-types"
import VueRouter from 'vue-router'
import { Modal } from 'ant-design-vue'

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = window._CONFIG['domianURL'] || "/jeecg-boot";
//console.log("apiBaseUrl= ",apiBaseUrl)

// 创建 axios 实例
const service = axios.create({
  //baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 30000 // was 9000ms; increased to a more convenient value (although we should still avoid long requests)
})

const err = (error: AxiosError) => {
  const req = error.request;
  if (req) {
    const respUrl = req.responseURL
    if (respUrl) { // respUrl could be empty if we are getting a connection timeout
      console.debug("axios error: respURL:", respUrl)
      const respURL = new URL(respUrl);
      const shouldRefreshTokenS = respURL.searchParams.get("shouldRefreshToken");
      const shouldRefreshToken = shouldRefreshTokenS ? shouldRefreshTokenS === "true" : true;
      if (!shouldRefreshToken) return Promise.reject(error); // for server status polling requests from GdsdxStatusView, do not notify the user directly about the errors
    }
  }
  if (error.response) {
    let data = error.response.data // the entity part of the response; can be undefined in certain cases (request abortion?)
    const token = Vue.ls.get(ACCESS_TOKEN) // the variable name in local storage is actually `pro__Access_Token`; note the key prefix in `defaultSettings.ts`.
    const httpStatus = error.response.status
    console.log("Received error response: token: %s, HTTP status: %d", token, httpStatus)
    // NOTE: At the server side, authentication errors in ShiroRealm would result in AuthenticationException, and JwtFilter will then give the "Token失效" errors with HTTP status 401 and response status 401.
    switch (httpStatus) { // the HTTP status code
      case 403:
        Vue.prototype.$Jnotification.error({ message: 'Error', description: 'Access denied', duration: 4})
        break
      case 500:
        console.log("------error.response------",error.response)
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        let type=error.response.request.responseType;
        if(type === 'blob'){
          blobToJson(data as Blob);
          break;
        }
        // update-end- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        if(token && (data as any).message.includes("Token失效")){
          // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
          // FIXME: As of Apr 3 2024, we are actually seeing 401 when the token expires, so we don't reach here
          if (/wxwork|dingtalk/i.test(navigator.userAgent)) {
            Vue.prototype.$Jmessage.loading('Session expired, attempting login again', 0)
          } else {
            Vue.prototype.$Jmodal.error({
              title: 'Session expired',
              content: 'Sorry, your session has expired, please login again',
              okText: 'Login',
              mask: false,
              onOk: () => {
                store.dispatch('Logout').then(() => {
                  Vue.ls.remove(ACCESS_TOKEN) // should not be necessary as this is already being done by the Logout action; see store/user.js.
                  try {
                    let path = window.document.location.pathname
                    console.log('location pathname -> ' + path)
                    if (path != '/' && path.indexOf('/user/login') == -1) {
                      window.location.reload()
                    }
                  } catch (e) {
                    window.location.reload()
                  }
                })
              }
            })
          }
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }
        break
      case 404:
        Vue.prototype.$Jnotification.error({ message: 'Error', description:'Resource not found',duration: 4})
        break
      case 504:
        Vue.prototype.$Jnotification.error({ message: 'Error', description: 'Network timeout'})
        break
      case 401:
        Vue.prototype.$Jnotification.error({ message: 'Error', description:'Session expired, please login again',duration: 4})
        if (token) {
          store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
        break
      default:
        Vue.prototype.$Jnotification.error({
          message: 'Error',
          description: (data as any)?.message,
          duration: 4
        })
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      Vue.prototype.$Jnotification.error({message: 'Error', description: 'Network timeout'})
    } else {
      Vue.prototype.$Jnotification.error({message: 'Error', description: error.message})
    }
  }
  // vue-concurrency requires that an exception be thrown (i.e. the promise be rejected) if the response code is 4xx or 5xx;
  // This should be true here, since the default validateStatus function does treat 4xx and 5xx as errors, taking us to this handler that still gives a rejection.
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  const headers = config.headers;  if (!headers) throw new Error("config.headers is not expected to be null"); // FIXME: can we really modify config in the interceptor?
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }

  // update-begin--author:sunjianlei---date:20200723---for 如果当前在low-app环境，并且携带了appId，就向Header里传递appId
  const $route = router.currentRoute
  if ($route && $route.name && $route.name.startsWith('low-app') && $route.params.appId) {
    headers['X-Low-App-ID'] = $route.params.appId
  }
  // update-end--author:sunjianlei---date:20200723---for 如果当前在low-app环境，并且携带了appId，就向Header里传递appId

  //update-begin-author:taoyan date:2020707 for:多租户
  let tenantid = Vue.ls.get(TENANT_ID)
  if (!tenantid) {
    tenantid = 0;
  }
  headers[ 'tenant-id' ] = tenantid
  //update-end-author:taoyan date:2020707 for:多租户
  if(config.method=='get'){
    if (config.url !== undefined && config.url.indexOf("sys/dict/getDictItems")<0){
      config.params = {
        _t: Date.now()/1000,
        ...config.params
      }
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer: PluginObject<VueRouter | {}> = {
  vm: {},
  install (Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}
/**
 * Blob解析
 * @param data
 */
function blobToJson(data: Blob) {
  let fileReader = new FileReader();
  let token = Vue.ls.get(ACCESS_TOKEN);
  fileReader.onload = function() {
    try {
      let jsonData = JSON.parse((this.result) as string);  // 说明是普通对象数据，后台转换失败
      console.log("jsonData",jsonData)
      if (jsonData.status === 500) {
        console.log("token----------》",token)
        if(token && jsonData.message.includes("Token失效")){
          Modal.error({
            title: 'Session expired',
            content: 'Sorry, your session has expired, please login again',
            okText: 'Login',
            mask: false,
            onOk: () => {
              store.dispatch('Logout').then(() => {
                Vue.ls.remove(ACCESS_TOKEN)
                window.location.reload()
              })
            }
          })
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err",err)
    }
  };
  fileReader.readAsText(data)
}

// Due to the response interceptor extracting the `data` component, `service`'s original type, `AxiosInstance` that returns an `AxiosPromise`, is no longer currect.
export interface GdsdxApi {
  (config: AxiosRequestConfig): Promise<any>;
  (url: string, config?: AxiosRequestConfig): Promise<any>;
  getUri(config?: AxiosRequestConfig): string;
  request<T = any, D = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
  delete<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
  head<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
  options<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
  post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
  put<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
  patch<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
  postForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
  putForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
  patchForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T>;
}
const gdsdxApi = service as GdsdxApi;

export {
  installer as VueAxios,
  gdsdxApi as axios
}