import Vue from 'vue'
import { USER_INFO, ENHANCE_PRE } from "@/store/mutation-types"
import { ensureStateVar, RootState } from './state-types';
const getters = {
  device: (state: RootState) => state.app.device,
  theme: (state: RootState) => state.app.theme,
  color: (state: RootState) => state.app.color,
  token: (state: RootState) => state.user.token,
  avatar: (state: RootState) => {state.user.avatar = Vue.ls.get(USER_INFO).avatar; return state.user.avatar},
  username: (state: RootState) => state.user.username,
  nickname: (state: RootState) => {state.user.realname = Vue.ls.get(USER_INFO).realname; return state.user.realname},
  welcome: (state: RootState) => state.user.welcome,
  permissionList: (state: RootState) => state.user.permissionList,
  authList: (state: RootState) => state.user.authList,
  userInfo: (state: RootState) => {state.user.info = Vue.ls.get(USER_INFO); return state.user.info},
  addRouters: (state: RootState) => state.permission.addRouters,
  onlAuthFields: (state: RootState) => {return state.online.authFields },
  enhanceJs: (state: RootState) => (code: string) => {
    state.enhance.enhanceJs[code] = Vue.ls.get(ENHANCE_PRE+code);
    return state.enhance.enhanceJs[code]
  },
  sysSafeMode: (state: RootState) => state.user.sysSafeMode,
  socInfoMap: (state: RootState) => ensureStateVar(state.gdsdx.socInfoMap),
  dspInfoMap: (state: RootState) => ensureStateVar(state.gdsdx.dspInfoMap),
  dspInfoMapByNameL: (state: RootState) => ensureStateVar(state.gdsdx.dspInfoMapByNameL),
  dspSvcInfoMap: (state: RootState) => ensureStateVar(state.gdsdx.dspSvcInfoMap),
  // We don't include trivial getters like showDebugOpts; just access the state directly
}
type StoreGettersDef = typeof getters;
export type StoreGetters = {
  [Prop in keyof StoreGettersDef]: StoreGettersDef[Prop] extends (state: RootState) => infer R ? R : never; // FIXME
};

export default getters
