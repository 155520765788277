// "store" should come from a useStoreT() call, which can only occur in the setup function of a Vue component.
import Vue, { VNode } from 'vue';
import { Store } from 'vuex';
import { printf } from 'fast-printf'; // the package named "printf" seems to be npm-only
import { AssetSpecType, ImpFileListV, ImpMatchQFV, ImpMatchV, ImpOwnerPubV, ImpWriterV, MatchPolicy, RecvOrigin } from '@/api/gdsdx/socSend';
import { CreatorV, DspAssetIdV, DspInfo, DspSvcInfo, LocalDateRange, Res, SocInfo, SocWorkcode, TimeRange, XDspIds, XSocCodes } from '@/api/gdsdx/baseApis';
import type { RootState } from '@/store/state-types';
import moment, { Moment } from 'moment';
import { AuditEntStatusV } from '@/api/gdsdx/auditLog';
import { AbortSignalWithPromise } from 'vue-concurrency/dist/vue2/src/types';
import { CAF } from 'caf';
import sleep from 'sleep-promise';

// It's messy to treat blank strings (i.e. those containing only whitespace) as null as well, so we don't bother.
export function emptyAsUndef(s: string | null | undefined): string | undefined { return s ? s : undefined; }
export function emptyArrAsUndef<T>(s: T[] | undefined): T[] | undefined { return (s !== undefined && s.length > 0) ? s : undefined; }

// Used as a "model" class when we need something that can be toggled on/off, but the contents are preserved (but disabled) even when off.
export type OptM<M> = { enabled: boolean; dataM: M };
export function enabledMO<M>(dataM: M): OptM<M> { return { enabled: true, dataM }; }
export function disabledMO<M>(dataM: M): OptM<M> { return { enabled: false, dataM }; }
export function wrapOpt<T,M>(dataO: T | null | undefined, newDataM: () => M, wrapData: (data: T) => M): OptM<M> {
  return (dataO != null) ? enabledMO(wrapData(dataO)) : disabledMO(newDataM());
}
export function unwrapOpt<T,M>({ enabled, dataM }: OptM<M>, unwrapData: (dataM: M) => T): T | undefined {
  return enabled ? unwrapData(dataM) : undefined;
}
// Special case when wrapData and unwrapData are identity functions
export function wrapOptI<M>(dataO: M | null | undefined, newDataM: () => M): OptM<M> { return wrapOpt(dataO, newDataM, (data) => data); }
export function unwrapOptI<M>(dataMO: OptM<M>): M | undefined { return unwrapOpt(dataMO, (dataM) => dataM); }

export type WithSymKey<T> = { key: symbol; data: T; needSync: boolean }; // needSync is for needSync propagation from items to the array, and should only be read by calcAnyArrItemNeedSync()
export function wrapSymKey<T>(data: T): WithSymKey<T> { return { key: Symbol(), data, needSync: false }; } // Symbol() (without "new") returns a new unique symbol
export function unwrapSymKey<T>(dataMK: WithSymKey<T>): T { return dataMK.data; }
export function verifyItemKey<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol): T { // returns itemsMKs[i].data as long as the key matches (just in case insertion/deletion has invalidated the array index somehow)
  const itemMK = itemMKs[i];  if (itemMK.key !== key) throw new Error(`mutArrItem[i=${i}, key=${key.toString()}]: key mismatch`);
  return itemMK.data;
}

// NOTE: Beware of Vue2's reactivity limitations <https://v2.vuejs.org/v2/guide/reactivity#For-Arrays>.  In particular, replacing an array element requires the use of Vue.set(), although updArrItem() is not actually doing so (it is merely modifying the `data` property).
export function mutArrItem<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol, mutItem: (item: T) => void) { mutItem(verifyItemKey(itemMKs, i, key)); }
export function updArrItem<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol, newItem: T) {
  verifyItemKey(itemMKs, i, key);  itemMKs[i].data = newItem;
}
export function setArrItemNeedSync<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol, needSync: boolean) {
  verifyItemKey(itemMKs, i, key);  itemMKs[i].needSync = needSync;
}
export function calcAnyArrItemNeedSync<T>(itemMKs: WithSymKey<T>[]) { return !itemMKs.every(itemMK => !itemMK.needSync); }
export function pushArrItem<T>(itemMKs: WithSymKey<T>[], newItem: T): void { itemMKs.push(wrapSymKey(newItem)); }
export function insArrItem<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol, newItem: T): void { // modifies itemMKs in place
  if (i < itemMKs.length) verifyItemKey(itemMKs, i, key); // i can be equal to itemMKs.length, in which case we add the item at the end
  itemMKs.splice(i, 0, wrapSymKey(newItem));
}
export function delArrItem<T>(itemMKs: WithSymKey<T>[], i: number, key: symbol) {
  verifyItemKey(itemMKs, i, key);  itemMKs.splice(i, 1);
}

export function calcMinKey<T>(xs: T[], keyF: (x: T) => number): number | undefined {
  let minKey = undefined;
  for (const x of xs) { const key = keyF(x);  if (minKey === undefined || key < minKey) minKey = key; }
  return minKey;
}

export const nodashdDateFmt = "YYYYMMDD"; // for use with moment; see <http://momentjs.com/docs/#/displaying/format/>
// Allows a Moment interval to be edited by <TimeRangeView>.  The exact meaning of t0_ and t1_ (e.g. whether the time part matters, whether the interval is closed or open) depends on the build*() function used.
export type TimeRangeM = {
  isRange: boolean; // If false, both t0_ and t1_ equals to t_ (tx_ is not used since it is not shown); if true, t0_ is t_ and t1_ is tx_
  t_: Moment | null; // For t0_ and t1_, using null instead of undefined to make it more convenient to use with <a-date-picker>
  tx_: Moment | null;
};
type RawTimeRange = {
  t0_: Moment | null;
  t1_: Moment | null;
};
export function newTimeRangeM(): TimeRangeM { return { isRange: true, t_: null, tx_: null }; }
function buildRawTimeRange({ isRange, t_, tx_ }: TimeRangeM): RawTimeRange {
  return isRange ? { t0_: t_, t1_: tx_ } : { t0_: t_, t1_: t_ };
}
function buildTimeRangeDIFromRaw({ t0_, t1_ }: RawTimeRange): TimeRange { // From the start of LocalDate(t0_) to the end of LocalDate(t1_)
  const t0 = (t0_ !== null) ? moment(t0_).startOf('day').valueOf() : undefined; // note that startOf() mutates the Moment, so we need to copy t0_ first
  const t1 = (t1_ !== null) ? moment(t1_).endOf('day').valueOf()+1 : undefined; // [t0,t1) is the desired range
  return { t0, t1 };
}
export function buildTimeRangeDI(rangeM: TimeRangeM): TimeRange { return buildTimeRangeDIFromRaw(buildRawTimeRange(rangeM)); }
export function buildTimeRangeDIO(rangeM: TimeRangeM): TimeRange | undefined {
  const rawRange = buildRawTimeRange(rangeM);
  return ( rawRange.t0_ !== null || rawRange.t1_ !== null) ? buildTimeRangeDIFromRaw(rawRange) : undefined;
}
function buildLocalDateRangeFromRaw({ t0_, t1_ }: RawTimeRange): LocalDateRange {
  const startD = (t0_ !== null) ? moment(t0_).format(nodashdDateFmt) : undefined; // ignoring the time part
  const endD = (t1_ !== null) ? moment(t1_).format(nodashdDateFmt) : undefined;
  return { startD, endD }
}
export function buildLocalDateRange(rangeM: TimeRangeM): LocalDateRange { return buildLocalDateRangeFromRaw(buildRawTimeRange(rangeM)); }
export function buildLocalDateRangeO(rangeM: TimeRangeM): LocalDateRange | undefined {
  const rawRange = buildRawTimeRange(rangeM);
  return (rawRange.t0_ !== null || rawRange.t1_ !== null) ? buildLocalDateRangeFromRaw(rawRange) : undefined;
}

// Report an exception to the user
export function showError(ex: any) {
  Vue.prototype.$Jnotification.error({message: 'Error', description: ex.toString()});
}

export const dbgSleep = CAF(function*(signal: AbortSignalWithPromise, dbgLabel: string, delayMs: number) {
  console.debug(`dbgSleep[${dbgLabel}]: ${delayMs}ms`);
  yield sleep(delayMs);
  console.debug(`dbgSleep[${dbgLabel}]: finished`);
});

export function extractResData<T>(res: Res<T>): T {
  if (!res.success) throw new Error("Request failed: code: " + res.code + "; message: " + res.message);
  // data can be null or undefined if T is nullable
  return res.data;
}

// format dates and times; uses local time (browser timezone) by default.
// Note that moment(XXX) also uses browser timezone by default; i.e. it parses XXX into an instant (using the browser timezone if XXX is a local date/time value without timezone information) and then converts it to a local date/time in the browser timezone.
//   See [here](https://momentjs.com/guides/#/parsing/local-utc-zone/).
// NOTE: the moment library is considered deprecated; however, it is already heavily used in jeecg, so we might as well keep using it.

// short format: YYMMDD HH:mm:ss
export function fmtMomentShort(t: Moment): string { return t.format('YYMMDD HH:mm:ss'); }
export function fmtMomentShortO(t: Moment | undefined) : string | undefined { return t ? fmtMomentShort(t) : undefined; }
// date: YYYY-MM-DD, used when specifically requested by the customer
export function fmtMomentDate(t: Moment): string { return t.format('YYYY-MM-DD'); }
export function fmtMomentDateO(t: Moment | undefined) : string | undefined { return t ? fmtMomentDate(t) : undefined; }

// tv is milliseconds-since-epoch
export function parseTimeVal(tv: number): Moment { return moment(tv); }
export function parseTimeValO(tv: number | undefined) : Moment | undefined { return (tv !== undefined && tv !== -1) ? parseTimeVal(tv) : undefined; }
export function fmtTimeValShort(tv: number): string { return fmtMomentShort(parseTimeVal(tv)); }
export function fmtTimeValShortO(tv: number | undefined): string | undefined { return fmtMomentShortO(parseTimeValO(tv)); }

// In the biz microservice, Java LocalDate uses ISO-8601 format in JSON
// The returned Moment should also be regarded as a LocalDate; its timezone is that of the browser, but this is not necessarily correct and should thus not be used.
export function parseLocalDate(dateStr: string): Moment { return moment(dateStr, moment.HTML5_FMT.DATE); }

// In the system microservice, instants are formatted in JSON as "yyyy-MM-dd HH:mm:ss" in the GMT+8 timezone.  We call such values "jtime" (jeecg time).
// jt is a jtime, and we return a Moment representing the same instant in the browser timezone.
export const jtimeFmt = "YYYY-MM-DD HH:mm:ss";
export function parseJtime(jt: string): Moment {
  // the moment methods are fluent-style; they modify the original object and return it
  return moment(jt, jtimeFmt).utcOffset(480, true).local(); // In the utcOffset() call, 480 is +08:00, and true preserves the local date/time and modifies the timezone.  The local() call then converts the same instant to the browser timezone.
}
export function parseJtimeO(jt: string | undefined): Moment | undefined { return jt ? parseJtime(jt) : undefined; }
export function fmtJtimeShort(jt: string): string { return fmtMomentShort(parseJtime(jt)); }
export function fmtJtimeShortO(jt: string | undefined) : string | undefined { return fmtMomentShortO(parseJtimeO(jt)); }

export function fmtIdents(idents: string[]): string { return idents.join(", "); }
export function parseIdents(identsStr: string): string[] {
  const rawIdents = identsStr.split(/[\s,]+/); // the separator can be comma or whitespace or a mixture of both
  const idents: string[] = [];
  for (let ident of rawIdents) {
    ident = ident.trim();  if (ident.length === 0) continue;
    idents.push(ident);
  }
  return idents;
}

const socCodePat = /^\d{1,3}$/; // test() can start and end anywhere by default, so ^ and $ are necessary.  We also allow the omission of leading zeroes
export function validateSocCode(socCode: number): number {
  if (! (socCode >= 0 && socCode <= 999)) throw new Error(`Invalid society code: ${socCode}`);
  return socCode;
}

export function fmtSocCode(socCode: number): string { return printf("%03d", socCode); }
export function fmtSocCodeO(socCode: number | null | undefined): string | undefined {
  return (socCode && socCode !== -1) ? fmtSocCode(socCode) : undefined;
}
export function fmtSocCodeT(socCodex: number | null | undefined): string {
  return (socCodex === XSocCodes.TOTAL) ? "" : (fmtSocCodeO(socCodex) ?? "---"); // In <ImrDspView>, we don't want to display anything in the society code column for the total
}

export function fmtSocCodes(socCodes: number[]): string { return socCodes.map(fmtSocCode).join(", "); }

export function parseSocCode(socCodeStr: string): number {
  if (socCodePat.test(socCodeStr)) return parseInt(socCodeStr, 10);  else throw new Error(`Invalid society code: ${socCodeStr}`);
}
export function parseSocCodes(socCodesStr: string): number[] {
  const socCodeSs = socCodesStr.split(/[\s,]+/); // the separator can be comma or whitespace or a mixture of both
  const socCodes: number[] = [];
  for (let socCodeS of socCodeSs) {
    socCodeS = socCodeS.trim();  if (socCodeS.length === 0) continue;
    socCodes.push(parseSocCode(socCodeS));
  }
  return socCodes;
}

export function fmtSocNameO_(socInfoMap: SocInfoMap) {
  return (socCode: number | null | undefined) => {
    // fall back to society code if society name is unknown
    socCode = socCode ?? -1;  return (socCode !== -1) ? (socInfoMap[socCode]?.name ?? fmtSocCode(socCode)) : undefined;
  };
}
export function fmtSocNameT_(socInfoMap: SocInfoMap) {
  const fmtSocNameO = fmtSocNameO_(socInfoMap);
  return (socCodex: number | null | undefined) => (socCodex === XSocCodes.TOTAL) ? "Total" : (fmtSocNameO(socCodex) ?? "N/A");
}

// `asocCode` is the active society of the current user, derived from the jeecg department number (LoginUser#orgCode).  See the XreqInfo comments in the backend code.

// Returns -1 if unspecified (FIXME: make this case easier to test)
// FIXME: Perhaps we should make this a getter of the vuex store?
export function tryGetAsocCode(store: Store<RootState>): number {
  const orgCode: string | null | undefined = store.getters.userInfo?.orgCode;
  if (orgCode === null || orgCode === undefined) return -1;
  if (socCodePat.test(orgCode)) return parseInt(orgCode);
  else { console.warn("orgCode does not have the form of a society code:", orgCode); return -1; }
}
export function fmtAsocCodeO(asocCode: number): string {
  return (asocCode !== -1) ? fmtSocCode(asocCode) : "*";
}

export function fmtSwcO(swc: SocWorkcode | null | undefined) { return swc ? swc.wc + "/" + fmtSocCode(swc.socCode) : ""; }

// The "A" suffix means that only the assetId is output; the dspId is omitted
export function fmtDaAO(da: DspAssetIdV | null | undefined) { return da ? da.assetId : ""; }
export function fmtDasAO(das: DspAssetIdV[] | null | undefined) {
  return das ? das.map(fmtDaAO).join(" ") : "";
}

export function fmtIsrcsO(isrcs: string[] | null | undefined) { return isrcs ? isrcs.join(" ") : ""; }
// NOTE: Alternative titles can themselves contain spaces, so we use newlines as the separator.  We need ellipsis support with the full contents shown in a tooltip, so we must format the cell contents as plain text instead of HTML; see MatchesView.vue.
export function fmtAltTitlesO(altTitles: string[] | null | undefined) { return altTitles ? altTitles.join("\n") : ""; }
export function fmtTitleAltTitlesO(title: string | null | undefined, altTitles: string[] | null | undefined) {
  const allTitles = [];  if (title) allTitles.push(title);  if (altTitles) allTitles.push(...altTitles);
  return allTitles.join("\n");
}
export function fmtMatchTitleAltTitles(match: ImpMatchV) { return fmtTitleAltTitlesO(match.title, match.altTitles); }
export function fmtWriterO(writer: ImpWriterV | null | undefined) { return writer ? writer.name : ""; } // maybe we can show the ISNI/IPI when we click open a single match record; there is too little space in a table.
export function fmtWritersO(writers: ImpWriterV[] | null | undefined) { return writers ? writers.map(fmtWriterO).join(" | ") : ""; }
export function fmtMpO(mp: MatchPolicy | null | undefined) {
  if (mp == null) return "";
  else switch (mp) {
    case MatchPolicy.ALL_COUNTRIES: return "All countries";
    default: return "INVALID_POLICY";
  }
}
export function fmtPubO(pub: ImpOwnerPubV | null | undefined) { return pub ? pub.pubName : ""; } // can show the ownership shares in a more verbose view
export function fmtPubsO(pubs: ImpOwnerPubV[] | null | undefined) { return pubs ? pubs.map(fmtPubO).join("\n") : ""; }

// territory codes
export function fmtTcodes(tcodes: string[]): string { return tcodes.join(' '); }
export function parseTcodes(tcodesS: string): string[] {
  const tcodes = [];
  for (let tcode of tcodesS.split(/[\s,]+/)) {
    tcode = tcode.trim();  if (tcode.length === 0) continue;
    tcode = tcode.toUpperCase();  tcodes.push(tcode);
  }
  return tcodes;
}

// the qualified name (Qname) of a file list, i.e. including both the name and the society code
export function fmtFlQname(fl: ImpFileListV, socInfoMap: SocInfoMap) {
  return '[' + ((fl.socCode !== -1) ? fmtSocCode(fl.socCode) : '*') + '] ' + (fl.forceName ?? socInfoMap[fl.socCode]?.name ?? fl.flSid);
}

export function fmtCreator(creator: CreatorV) {
  return (creator.userName ?? creator.uid) + ' [' + fmtAsocCodeO(creator.asocCode) + ']';
}
export function fmtCreatorO(creator: CreatorV | null | undefined) { return creator ? fmtCreator(creator) : undefined; }

// Must be kept consistent with <AssetSpecView>
export function fmtAsType(asType: AssetSpecType) {
  switch (asType) {
    case AssetSpecType.InlineAssetIds: return "By asset ID";
    case AssetSpecType.InlineIsrcs: return "By ISRC";
    case AssetSpecType.File: return "Upload list";
    default: throw new Error(`Invalid asType in fmtAsType(): ${asType}`);
  }
}

export function fmtAuditEntStatus(status: AuditEntStatusV) { return status.isOk ? 'OK' : (status.msg ?? 'Failed'); }
export function fmtAuditEntStatusO(status: AuditEntStatusV | null | undefined) { return status ? fmtAuditEntStatus(status) : undefined; }

export function recvOriginToStyle(rawOrigin: RecvOrigin | null | undefined) {
  const origin = rawOrigin ?? RecvOrigin.NONE;  return { 'gdsdx-origin-derived': origin === RecvOrigin.DERIVED };
}

// MSG: notify with messages (in the GDSDX UI) only; MSG_EMAIL: notify with both messages and e-mail
export const enum NotifyType { NONE = 0, MSG = 1, MSG_EMAIL = 2 }
export function calcShouldNotify(ntype: NotifyType) { return ntype >= NotifyType.MSG; }
export function calcShouldNotifyEmail(ntype: NotifyType) { return ntype >= NotifyType.MSG_EMAIL; }

// Type of the content download by DlLinkBtn; LOG: textual log; DL_OUT: downloadable output files
export const enum DlType { LOG = 1, DL_OUT = 2 }

// Display mode of MatchesView; VIEW: viewing a single file; SRCH: previewing search results
export const enum MatchesViewMode { VIEW = 1, SRCH = 2 }

// We use plain JavaScript objects here instead of more complicated data structures like Map (note that numeric indices are automatically casted to strings when using plain JavaScript objects),
// because we want to put these into the VueX state, where these objects will be made reactive.
export interface SocInfoMap {
  [socCode: number]: SocInfo;
}
export interface DspInfoMap {
  [dspId: number]: DspInfo;
}
export interface DspSvcInfoMap {
  [svcId: number]: DspSvcInfo;
}
export function makeDspNameL(dspName: string) { return dspName.replace(' ', '').toLowerCase(); }
export interface DspInfoMapByNameL {
  [dspNameL: string]: DspInfo; // dspNameL is makeDspNameL(dspName)
}

function doFmtDsp(dspInfoMap: DspInfoMap, dspId: number): string { // Here dspId does not support -1/null/undefined
  return dspInfoMap[dspId]?.dspName ?? "UNKNOWN_DSP"; // Distinguish between unexpected dspId and -1/null/undefined
}
export function fmtDspO(dspInfoMap: DspInfoMap) {
  return (dspId: number | null | undefined) => {
    dspId = dspId ?? -1;  return (dspId !== -1) ? doFmtDsp(dspInfoMap, dspId) : undefined;
  };
}
function doFmtDspSvc(dspSvcInfoMap: DspSvcInfoMap, svcId: number): string {
  return dspSvcInfoMap[svcId]?.svcName ?? "UNKNOWN_SVC";
}
export function fmtDspSvcO(dspSvcInfoMap: DspSvcInfoMap) {
  return (svcId: number | null | undefined) => {
    svcId = svcId ?? -1;  return (svcId !== -1) ? doFmtDspSvc(dspSvcInfoMap, svcId) : undefined;
  };
}
export function fmtDspIds(dspIds: number[], dspInfoMap: DspInfoMap) {
  return dspIds.map(dspId => doFmtDsp(dspInfoMap, dspId)).join(", ");
}

export function fmtXDspO(dspInfoMap: DspInfoMap) {
  return (xDspId: number | null | undefined) => {
    if (xDspId === null || xDspId === undefined) return undefined;
    switch (xDspId) {
      case XDspIds.NONE: return "";
      case XDspIds.MIXED: return "Mixed";
    }
    return doFmtDsp(dspInfoMap, xDspId);
  };
}

export interface IMultiRow { iRow: number; nRow: number; }
export function fmtRowSpan({ iRow, nRow }: IMultiRow, valS: string | VNode | VNode[] | undefined) {
  return { children: valS, attrs: { rowSpan: (iRow === 0) ? nRow : 0 } };
}

// svcId==-1 is always considered valid.  Otherwise, dspId must not be -1, and svcId must belong to this dsp
export function validateSvcId(dspSvcInfoMap: DspSvcInfoMap, dspId: number, svcId: number) {
  if (svcId === -1) return;
  const svc = dspSvcInfoMap[svcId];  if (!svc) throw new Error("Invalid svcId: " + svcId);
  if (!(dspId !== -1 && dspId === svc.dspId)) throw new Error("Invalid service " + svc.svcName + " for the selected DSP; please choose a valid service type for the selected DSP");
}

// Returns a sorted array of non-unspecified DspSvcInfo's (empty if dspId is -1).
export function calcValidSvcs(dspSvcInfoMap: DspSvcInfoMap, dspId: number): DspSvcInfo[] {
  const validSvcs: DspSvcInfo[] = [];
  for (const svcIdS in dspSvcInfoMap) { // dspSvcInfoMap is a plain object, so it can't be enumerated with for...of
    const svcId = parseInt(svcIdS);
    const svc = dspSvcInfoMap[svcId];  if (svc.dspId !== dspId) continue;
    validSvcs.push(svc);
  }
  validSvcs.sort((sa, sb) => sa.svcId - sb.svcId);  return validSvcs;
}

// editedMatch must be a unique item of matches; it is updated to newMatch.  Must ensure that matches can safely be modified (e.g. it should usually not be a prop, but modifying a task output is probably okay)
// Not placed in editUtils.ts since it is used everywhere that needs <MatchesView>.
export function updEditedMatch(matches: ImpMatchQFV[], editedMatch: ImpMatchQFV, newMatch: ImpMatchV) {
  const idx = matches.findIndex((match) => match === editedMatch);  if (idx === -1) throw new Error("Failed to find the edited match"); // this just ensures that editedMatch can be modified
  // NOTE: We want to replace all ImpMatchV fields, and preserve the ImpMatchQFV fields that do not appear in ImpMatchV.
  // Some ImpMatchV fields, such as isDeleted, can be omitted when null/undefined; hence, `Object.assign(editedMatch, newMatch);` would behave incorrectly when a match record previously marked deleted gets undeleted.
  if (editedMatch.rId !== newMatch.rId) throw new Error("rId mismatch in updEditedMatch()");
  editedMatch.isDeleted = newMatch.isDeleted;  editedMatch.ei = newMatch.ei;  editedMatch.fId = newMatch.fId;  editedMatch.swc = newMatch.swc;  editedMatch.iswc = newMatch.iswc;
  editedMatch.isrcs = newMatch.isrcs;  editedMatch.rDspId0 = newMatch.rDspId0;  editedMatch.das = newMatch.das;  editedMatch.title = newMatch.title;  editedMatch.altTitles = newMatch.altTitles;
  editedMatch.writers = newMatch.writers;  editedMatch.mp = newMatch.mp;  editedMatch.pubs = newMatch.pubs;  editedMatch.oinc = newMatch.oinc;
}