/** init domain config */
import './config'

import Vue from 'vue'
import App from './App.vue'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
// import UmyUi from 'umy-ui'
// import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
import Storage from 'vue-ls'
import router from './router'
import store from './store'
import { VueAxios } from "@/utils/request"



// Not used right now; if we want to use it, remember to uncomment the routing code in util.js as well.
//import '@jeecg/antd-online-mini'
//import '@jeecg/antd-online-mini/dist/OnlineForm.css'

import Antd from 'ant-design-vue'
// console.log('ant-design-vue version:', version) // ant-design-vue is not exporting a version in its TypeScript definitions

// When we import antd.less, we use our own less-loader to process it, instead of using the pre-generated antd.css and its corresponding source map.
//import 'ant-design-vue/dist/antd.less';  // this includes lib/style/{index,components}.less
import '@/components/index.less'; // customized version of lib/style/index.less
import 'ant-design-vue/lib/style/components.less'; // FIXME: lazy-loading with babel-plugin-import?

// NOTE: I think we have not enabled CSS modules in css-loader, so gdsdx.less is loaded globally regardless of whether it is import'd from JS or @import'd from CSS (even scoped CSS).
// If we want to avoid polluting the global namespace for e.g. CSS class names, we can enable CSS modules in css-loader, in which case affected local class names will be mangled, importing the CSS module in Javascript
// gives a mapping from the local class name to the mangled name (<https://github.com/css-modules/css-modules/blob/master/README.md>), and other CSS code referencing this CSS module (usually by composing a local class)
// using the `composes: className from "./style.css"` syntax will also have `className` mapped to the mangled name and added to the class name lists of selectors using such composition.
//import '@/assets/less/gdsdx.less'; // it is too error-prone to import gdsdx.less everywhere

//import Viser from 'viser-vue'

import '@/permission' // permission control
import '@/utils/filter' // base filter
import Print from 'vue-print-nb-jeecg'
/*import '@babel/polyfill'*/
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import SSO from '@/cas/sso.js'
import {
  ACCESS_TOKEN,
  DEFAULT_COLOR,
  DEFAULT_THEME,
  DEFAULT_LAYOUT_MODE,
  DEFAULT_COLOR_WEAK,
  SIDEBAR_TYPE,
  DEFAULT_FIXED_HEADER,
  DEFAULT_FIXED_HEADER_HIDDEN,
  DEFAULT_FIXED_SIDEMENU,
  DEFAULT_CONTENT_WIDTH_TYPE,
  DEFAULT_MULTI_PAGE
} from "@/store/mutation-types"
import config from '@/defaultSettings'

import JDictSelectPlugin from './components/dict/index.js' // no longer does global registration
import hasPermission from '@/utils/hasPermission'
import vueBus from '@/utils/vueBus';
import JeecgComponents from '@/components/jeecg/index' // no longer does global registration

// vxe-table seems to be unused so far; we are already having mysterious problems in JVxeDemo1 regarding this.column.own not existing anyway.
//import '@/components/jeecg/JVxeTable/install'
//import '@/components/JVxeCells/install'

//表单验证
import { rules } from '@/utils/rules'
Vue.prototype.rules = rules
Vue.config.productionTip = false

Vue.use(Storage, config.storageOptions)
Vue.use(Antd)
Vue.use(VueAxios, router)
//Vue.use(Viser)
Vue.use(hasPermission)
Vue.use(JDictSelectPlugin)
Vue.use(Print)
Vue.use(preview)
Vue.use(vueBus);
Vue.use(JeecgComponents);
//Vue.use(VueAreaLinkage);

// Vue.use(UmyUi);



SSO.init(() => {
  main()
})
function main() {
  new Vue({
    router,
    store,
    mounted () {
      store.commit('SET_SIDEBAR_TYPE', Vue.ls.get(SIDEBAR_TYPE, true))
      store.commit('TOGGLE_THEME', Vue.ls.get(DEFAULT_THEME, config.navTheme))
      store.commit('TOGGLE_LAYOUT_MODE', Vue.ls.get(DEFAULT_LAYOUT_MODE, config.layout))
      store.commit('TOGGLE_FIXED_HEADER', Vue.ls.get(DEFAULT_FIXED_HEADER, config.fixedHeader))
      store.commit('TOGGLE_FIXED_SIDERBAR', Vue.ls.get(DEFAULT_FIXED_SIDEMENU, config.fixSiderbar))
      store.commit('TOGGLE_CONTENT_WIDTH', Vue.ls.get(DEFAULT_CONTENT_WIDTH_TYPE, config.contentWidth))
      store.commit('TOGGLE_FIXED_HEADER_HIDDEN', Vue.ls.get(DEFAULT_FIXED_HEADER_HIDDEN, config.autoHideHeader))
      store.commit('TOGGLE_WEAK', Vue.ls.get(DEFAULT_COLOR_WEAK, config.colorWeak))
      store.commit('TOGGLE_COLOR', Vue.ls.get(DEFAULT_COLOR, config.primaryColor))
      store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
      store.commit('SET_MULTI_PAGE',Vue.ls.get(DEFAULT_MULTI_PAGE,config.multipage))
    },
    render: h => h(App)
  }).$mount('#app')
}