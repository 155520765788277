import JModal from './JModal'
//jeecgbiz
//引入需要全局注册的js函数和变量
import { message, Modal, notification } from 'ant-design-vue'
//import data from 'china-area-data'
import lodash_object from 'lodash'
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'

export default {
  install(Vue) {
    Vue.use(JModal)
    // We now rely on unplugin-vue-components to automatically register the components locally.  While the original render function from the Vue template compiler still
    // refers to components like <j-select-depart> by name, in the final output it is transformed into e.g. `i["default"]`, becoming a local module import directly.
    // To test whether the components are still working, use "常见案例 / 自定义组件" (might need to unhide the route and give permission to some role first), corresponding to SelectDemo.vue.
    // 
    // Vue.component('JMarkdownEditor', JMarkdownEditor)
    // Vue.component('JPopupOnlReport', JPopupOnlReport)
    // Vue.component('JFilePop', JFilePop)
    // Vue.component('JInputPop', JInputPop)
    // Vue.component('JAreaLinkage', JAreaLinkage)
    // Vue.component('JCategorySelect', JCategorySelect)
    // Vue.component('JCheckbox', JCheckbox)
    // Vue.component('JCodeEditor', JCodeEditor)
    // Vue.component('JCron', JCron)
    // Vue.component('JDate', JDate)
    // Vue.component('JEditableTable', JEditableTable)
    // Vue.component('JEditor', JEditor)
    // Vue.component('JEllipsis', JEllipsis)
    // Vue.component('JFormContainer', JFormContainer)
    // Vue.component('JImageUpload', JImageUpload)
    // Vue.component('JImportModal', JImportModal)
    // Vue.component('JInput', JInput)
    // Vue.component('JPopup', JPopup)
    // Vue.component('JSelectMultiple', JSelectMultiple)
    // Vue.component('JSlider', JSlider)
    // Vue.component('JSuperQuery', JSuperQuery)
    // Vue.component('JSwitch', JSwitch)
    // Vue.component('JTime', JTime)
    // Vue.component('JTreeDict', JTreeDict)
    // Vue.component('JTreeSelect', JTreeSelect)
    // Vue.component('JTreeTable', JTreeTable)
    // Vue.component('JUpload', JUpload)

    //jeecgbiz
    // Vue.component('JSelectDepart', JSelectDepart)
    // Vue.component('JSelectMultiUser', JSelectMultiUser)
    // Vue.component('JSelectPosition', JSelectPosition)
    // Vue.component('JSelectRole', JSelectRole)
    // Vue.component('JSelectUserByDep', JSelectUserByDep)
    // Vue.component(JEasyCron.name, JEasyCron)

    //注册全局js函数和变量
    Vue.prototype.$Jnotification = notification
    Vue.prototype.$Jmodal = Modal
    Vue.prototype.$Jmessage = message
    Vue.prototype.$Jlodash = lodash_object
    Vue.prototype.$Jdebounce= debounce
    Vue.prototype.$Jpick = pick
    //Vue.prototype.$Jpcaa = data
  }
}