import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { getClass as _getClass, getStyle as _getStyle } from '@/utils/props-util';
import { triggerWindowResizeEvent } from '@/utils/util';
export default {
  name: 'JModal',
  props: {
    title: String,
    // 可使用 .sync 修饰符
    visible: Boolean,
    // 是否全屏弹窗，当全屏时无论如何都会禁止 body 滚动。可使用 .sync 修饰符
    fullscreen: {
      type: Boolean,
      default: false
    },
    // 是否允许切换全屏（允许后右上角会出现一个按钮）
    switchFullscreen: {
      type: Boolean,
      default: false
    },
    // 点击确定按钮的时候是否关闭弹窗
    okClose: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      // 内部使用的 slots ，不再处理
      usedSlots: ['title'],
      // 实际控制是否全屏的参数
      innerFullscreen: this.fullscreen
    };
  },
  computed: {
    // 一些未处理的参数或特殊处理的参数绑定到 a-modal 上
    _attrs: function _attrs() {
      var attrs = _objectSpread({}, this.$attrs); // 如果全屏就将宽度设为 100%


      if (this.innerFullscreen) {
        attrs['width'] = '100%';
      }

      return attrs;
    },
    modalClass: function modalClass() {
      return {
        'j-modal-box': true,
        'fullscreen': this.innerFullscreen,
        'no-title': this.isNoTitle,
        'no-footer': this.isNoFooter
      };
    },
    modalStyle: function modalStyle() {
      var style = {}; // 如果全屏就将top设为 0

      if (this.innerFullscreen) {
        style['top'] = '0';
      }

      return style;
    },
    isNoTitle: function isNoTitle() {
      return !this.title && !this.allSlotsKeys.includes('title');
    },
    isNoFooter: function isNoFooter() {
      return this._attrs['footer'] === null;
    },
    slotsKeys: function slotsKeys() {
      var _this = this;

      return Object.keys(this.$slots).filter(function (key) {
        return !_this.usedSlots.includes(key);
      });
    },
    scopedSlotsKeys: function scopedSlotsKeys() {
      var _this2 = this;

      return Object.keys(this.$scopedSlots).filter(function (key) {
        return !_this2.usedSlots.includes(key);
      });
    },
    allSlotsKeys: function allSlotsKeys() {
      return Object.keys(this.$slots).concat(Object.keys(this.$scopedSlots));
    },
    // 切换全屏的按钮图标
    fullscreenButtonIcon: function fullscreenButtonIcon() {
      return this.innerFullscreen ? 'fullscreen-exit' : 'fullscreen';
    }
  },
  watch: {
    visible: function visible() {
      if (this.visible) {
        this.innerFullscreen = this.fullscreen;
      }
    },
    innerFullscreen: function innerFullscreen(val) {
      this.$emit('update:fullscreen', val);
    }
  },
  methods: {
    getClass: function getClass(clazz) {
      return _objectSpread(_objectSpread({}, _getClass(this)), clazz);
    },
    getStyle: function getStyle(style) {
      return _objectSpread(_objectSpread({}, _getStyle(this)), style);
    },
    close: function close() {
      this.$emit('update:visible', false);
    },
    handleOk: function handleOk() {
      if (this.okClose) {
        this.close();
      }
    },
    handleCancel: function handleCancel() {
      this.close();
    },

    /** 切换全屏 */
    toggleFullscreen: function toggleFullscreen() {
      this.innerFullscreen = !this.innerFullscreen;
      triggerWindowResizeEvent();
    }
  }
};