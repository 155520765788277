/** init domain config */
import Vue from 'vue'
import { URL } from 'whatwg-url'
/*
 * The browser can access the web server (e.g. the development server started by "yarn run serve") through multiple possible base URLs,
 * because a single server can have multiple IP addresses and domain names (for example, a local development server can be accessed from localhost, 127.0.0.1 or another IP address),
 * and there might be any number of reverse proxies in front of it.  Hence, it is not really possible to specify the user-visible base URL in the server configuration file (e.g. process.env.*).
 * 
 * The HTTP Host request header should contain the host/port used by the sender of the request to reach us.  However, this is per-hop information; that sender could be the upstream (closer to the user) proxy rather than the user's browser.
 *
 * When using https, the certificate must of course correspond to the user-visible URL, but https handling might well be done in an upstream proxy.
 * 
 * When doing CORS checking, such that a user accessing a different website cannot send arbitrary requests to our server, the server does need to determine
 * whether the Origin header specifies a host that points to our website or a different site.  Since the Origin header is usually not rewritten by upstream reverse proxies,
 * it can become quite different from the Host header, even if it does point to our site.
 * 
 * We can sidestep this problem by always using relative URLs.  However, some places (e.g. the websocket code in ShowAnnouncement.vue) expect window._CONFIG['domianURL'] to be an absolute URL.
 * Therefore we try to get the absolute base URL using Javascript code.
 */

/* See https://url.spec.whatwg.org/ for details of URL parsing.
 * In particular, the pathname part is divided into path items; if the pathname ends with /, there is an empty path item at the end.
 * When resolving a relative URL against a base URL, the last path item of the base URL get overwritten by the path items of the relative URL if the relative URL contains any path component at all.
 * Therefore, when using the WHATWG URL API, we should usually have an empty path item at the end of the base URL, i.e. it should have a trailing /.
 * However, in other places (e.g. servlet context paths), the trailing / is allowed to be absent or even stripped.  Hence the need for the URLBase class.
 * 
 * Naming conventions: xxxUrl has type string (and is regarded as a prefixUrl when indicated), xxxURL has type URL, xxxBase has type URLBase
 */ 
class URLBase {
  baseURL: URL;
  // this.baseURL is always a directory-like URL, with a trailing slash in its path.  In other words, after parsing according to https://url.spec.whatwg.org/, it contains a final empty path component.
  // It is also usually absolute.
  constructor(baseURL: URL) { this.baseURL = baseURL; }
  resolveToURL(relUrl: string): URL { return new URL(relUrl, this.baseURL); }
  resolveToUrl(relUrl: string): string { return this.resolveToURL(relUrl).href; }
  resolvePrefixToBase(rawPrefixUrl: string): URLBase { return URLBase.fromPrefixUrl(rawPrefixUrl, this.baseURL); }
  resolvePrefixToBaseO(rawPrefixUrl: string | undefined): URLBase | undefined { return rawPrefixUrl ? this.resolvePrefixToBase(rawPrefixUrl) : undefined; }
  toPrefixUrl() {
    const tmpUrl = this.baseURL.href;
    if (!tmpUrl.endsWith("/")) throw new Error("this.baseURL.href should end with /, but is actually: " + tmpUrl);
    // In the WHATWG specification, multiple consecutive slashes become empty path items in the middle.  They are not automatically folded into one.
    // However, the prefixUrl format can't preserve such information, so we still remove all trailing slashes.
    return tmpUrl.replace(/\/+$/, "");
  }
  // docUrl is not necessarily directory-like, i.e. its path part does not necessarily ends with a slash.  When used as a base url, the part after the final slash is ignored.
  static fromDocUrl(docUrl: string): URLBase { return new URLBase(new URL(".", docUrl)); }
  static fromDoc(doc: Document): URLBase { return URLBase.fromDocUrl(doc.baseURI); } // replaces the final path component of doc.baseURI with the empty string, and removes possible query/fragment parts
  // rawPrefixUrl is always intended to specify a directory, and its trailing slashes might be omitted.  A trailing slash (i.e. empty final path component) must be added before it can be used as a base url.
  static fromPrefixUrl(rawPrefixUrl: string, baseURL0?: string | URL): URLBase {
    const rawURL = new URL(rawPrefixUrl, baseURL0);
    const origPathname = rawURL.pathname; if (!origPathname.endsWith("/")) rawURL.pathname = origPathname + "/";
    return new URLBase(rawURL);
  }
}

declare global { // Window is in the global namespace, so we augment it within a "declare global" block.
  interface Window {
    _CONFIG: { domianURL: string, staticDomainURL: string, pdfDomainURL: string, casPrefixUrl?: string, onlinePreviewDomainURL?: string }
  }
}

export let apiBase: URLBase;

function parsePrefixUrl0(prefixUrl0: string, docBase: URLBase): string {
  if (prefixUrl0.startsWith(':')) { // prefixUrl0 is :PORT/PATH, and the part before the port number should come from docBase
    const docURL = docBase.baseURL;
    // See <https://url.spec.whatwg.org/#url-class>; `protocol` includes the trailing colon, and `hostname` does not include the port number (while `host` does).
    return docURL.protocol + "//" + docURL.hostname + prefixUrl0;
  } else return prefixUrl0;
}

(function () {
  const docBase = URLBase.fromDoc(document); // usually window.location, but can be overridden by the HTML <base> element
  // FIXME: docBase can be in a subdirectory, so the *_BASE_URL's, if relative, must at least specify an absolute path.
  // What if the entire project is not deployed at root?

  //console.log("prefix: " + parsePrefixUrl0(":9988/gdsdx-system", docBase));
  const rawApiPrefixUrl0 = process.env.VUE_APP_API_BASE_URL; if (!rawApiPrefixUrl0) throw new Error("VUE_APP_API_BASE_URL not specified in the environment");
  const rawApiPrefixUrl = parsePrefixUrl0(rawApiPrefixUrl0, docBase);
  apiBase = docBase.resolvePrefixToBase(rawApiPrefixUrl);
  window._CONFIG['domianURL'] = Vue.prototype.API_BASE_URL = apiBase.toPrefixUrl();
  window._CONFIG['staticDomainURL'] = apiBase.resolvePrefixToBase("sys/common/static").toPrefixUrl();
  window._CONFIG['pdfDomainURL'] = apiBase.resolvePrefixToBase("sys/common/pdf/pdfPreviewIframe").toPrefixUrl();

  // ?. is the optional chaining operator (Babel will translate it into something the browser understands); note that it returns undefined when the LHS is null or undefined
  window._CONFIG['casPrefixUrl'] = docBase.resolvePrefixToBaseO(process.env.VUE_APP_CAS_BASE_URL)?.toPrefixUrl(); //单点登录地址
  window._CONFIG['onlinePreviewDomainURL'] = docBase.resolvePrefixToBaseO(process.env.VUE_APP_ONLINE_BASE_URL)?.toPrefixUrl();
})();
