import pick from 'lodash.pick';
export default {
  name: 'JPrompt',
  data: function data() {
    return {
      visible: false,
      loading: false,
      content: '',
      // 弹窗参数
      modalProps: {
        title: ''
      },
      inputProps: {
        placeholder: ''
      },
      // form model
      model: {
        input: ''
      },
      // 校验
      rule: [],
      // 回调函数
      callback: {}
    };
  },
  computed: {
    rules: function rules() {
      return {
        input: this.rule
      };
    }
  },
  methods: {
    show: function show(options) {
      var _this = this;

      this.content = options.content;

      if (Array.isArray(options.rule)) {
        this.rule = options.rule;
      }

      if (options.defaultValue != null) {
        this.model.input = options.defaultValue;
      } // 取出常用的弹窗参数


      var pickModalProps = pick(options, 'title', 'centered', 'cancelText', 'closable', 'mask', 'maskClosable', 'okText', 'okType', 'okButtonProps', 'cancelButtonProps', 'width', 'wrapClassName', 'zIndex', 'dialogStyle', 'dialogClass');
      this.modalProps = Object.assign({}, pickModalProps, options.modalProps); // 取出常用的input参数

      var pickInputProps = pick(options, 'placeholder', 'allowClear');
      this.inputProps = Object.assign({}, pickInputProps, options.inputProps); // 回调函数

      this.callback = pick(options, 'onOk', 'onOkAsync', 'onCancel');
      this.visible = true;
      this.$nextTick(function () {
        return _this.$refs.input.focus();
      });
    },
    onOk: function onOk() {
      var _this2 = this;

      this.$refs.form.validate(function (ok, err) {
        if (ok) {
          var event = {
            value: _this2.model.input,
            target: _this2
          }; // 异步方法优先级高于同步方法

          if (typeof _this2.callback.onOkAsync === 'function') {
            _this2.callback.onOkAsync(event);
          } else if (typeof _this2.callback.onOk === 'function') {
            _this2.callback.onOk(event);

            _this2.close();
          } else {
            _this2.close();
          }
        }
      });
    },
    onCancel: function onCancel() {
      if (typeof this.callback.onCancel === 'function') {
        this.callback.onCancel(this.model.input);
      }

      this.close();
    },
    onInputPressEnter: function onInputPressEnter() {
      this.onOk();
    },
    close: function close() {
      this.visible = this.loading ? this.visible : false;
    },
    forceClose: function forceClose() {
      this.visible = false;
    },
    showLoading: function showLoading() {
      this.loading = true;
    },
    hideLoading: function hideLoading() {
      this.loading = false;
    },
    afterClose: function afterClose(e) {
      if (typeof this.modalProps.afterClose === 'function') {
        this.modalProps.afterClose(e);
      }

      this.$emit('after-close', e);
    }
  }
};