import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import type { RootState } from './state-types'
import app from './modules/app'
import user from './modules/user'
import enhance from './modules/enhance'
import online from './modules/online'
import permission from './modules/permission'
import gdsdx from './modules/gdsdx'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    enhance,
    online,
    gdsdx
  },
  state: {

  } as RootState,
  mutations: {

  },
  actions: {

  },
  getters
} as StoreOptions<RootState>)
