import enUS from 'ant-design-vue/lib/locale-provider/en_US';
import enquireScreen from '@/utils/device';
export default {
  data: function data() {
    return {
      locale: enUS
    };
  },
  created: function created() {
    var that = this;
    enquireScreen(function (deviceType) {
      // tablet
      if (deviceType === 0) {
        that.$store.commit('TOGGLE_DEVICE', 'mobile');
        that.$store.dispatch('setSidebar', false);
      } // mobile
      else if (deviceType === 1) {
        that.$store.commit('TOGGLE_DEVICE', 'mobile');
        that.$store.dispatch('setSidebar', false);
      } else {
        that.$store.commit('TOGGLE_DEVICE', 'desktop');
        that.$store.dispatch('setSidebar', true);
      }
    });
  }
};