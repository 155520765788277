import { AuthItem } from "@/utils/authUtils";
import { DspInfoMap, DspInfoMapByNameL, DspSvcInfoMap, SocInfoMap } from "@/utils/gdsdx/gdsdxUtils";

// It is hard to type Vuex3 modules properly; gdsdx should always be available in the state
export interface RootState {
  app: any;
  user: UserState;
  permission: any;
  enhance: any;
  online: any;
  gdsdx: GdsdxState;
}
export interface UserState {
  token: string;
  username: string;
  realname: string;
  tenantid: string;
  welcome: string;
  avatar: string;
  permissionList: any[];
  authList: AuthItem[];
  info: any;
  sysSafeMode: any;
}
export interface GdsdxState {
  // We do not currently save showDebugOpts to local storage, since it is meant to be enabled only occasionally.
  showDebugOpts: boolean;
  socInfoMap: SocInfoMap | undefined;
  dspInfoMap: DspInfoMap | undefined;
  dspInfoMapByNameL: DspInfoMapByNameL | undefined;
  dspSvcInfoMap: DspSvcInfoMap | undefined;
  pingVer: number;
}
export function ensureStateVar<T>(x_: T | undefined): T {
  if (x_ === undefined) throw Error('State variable not set');
  return x_;
}
