import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import notification from 'ant-design-vue/es/notification'
import { ACCESS_TOKEN,INDEX_MAIN_PAGE_PATH, OAUTH2_LOGIN_PAGE_PATH, INC_PING_VER } from '@/store/mutation-types'
import { generateIndexRouter, isOAuth2AppEnv } from '@/utils/util'
import type { RawLocation } from 'vue-router'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/user/login', '/user/register', '/user/register-result','/user/alteration'] // no redirect whitelist
whiteList.push(OAUTH2_LOGIN_PAGE_PATH)

// See <https://v3.router.vuejs.org/guide/advanced/navigation-guards.html>
router.beforeEach(async (to, from, next) => {
  NProgress.start() // start progress bar; should be stopped in afterEach()

  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token (possibly after a success login, possibly from a cached token in local storage that might or might not be valid) */
    if (to.path === '/user/login' || to.path === OAUTH2_LOGIN_PAGE_PATH) {
      const redirect = (to.query.redirect as string) || INDEX_MAIN_PAGE_PATH;
      next({ path: redirect }) // skip the login page and navigate to the intended page instead (if the cached access token has expired, we'd return to the login page with the access token cleared)
      //NProgress.done() // should now be done in afterEach()
    } else {
      if (store.getters.permissionList.length === 0) { // route initialization cannot simply be done at login time, since the access token might be one cached in local storage, and no login is necessary this time
        try {
          await store.dispatch('FetchSetup');
          const plRes = await store.dispatch('GetPermissionList');
          const menuData = plRes.result.menu;
          //console.log(res.message)
          if (menuData === null || menuData === "" || menuData === undefined) throw new Error("Missing menuData from GetPermissionList");
          let constRoutes = [];
          constRoutes = generateIndexRouter(menuData); // dynamic routes configured with 菜单管理
          // 添加主界面路由
          await store.dispatch('UpdateAppRouter',  { constRoutes });
          // 根据roles权限生成可访问的路由表
          // 动态添加可访问路由表
          router.addRoutes(store.getters.addRouters)
          // NOTE: Previously upon a successful login, loginSuccess() in Login.vue would attempt a navigation to an arbitrary page, and we want to reach the from.query.redirect (i.e. the redirect argument of the login page) instead.
          // Now that we simply reload the login page, this should no longer be necessary.
          // NOTE: The beforeEach() hook is called during the confirmTransition() step.  The current `to` is already a Route that results from matching against the previous route map (often empty), so we cannot simply confirm it with next().
          // Instead, we must trigger a redirect to the same path so that the route is looked up again.
          // After confirming the transition, updateRoute() will be called, which will set app._route and cause the router view to be updated reactively.
          next({ path: to.path, replace: true });
        } catch (e) {
          console.warn("Error fetching user information: " + e); // otherwise we'd see mysterious behavior if the failure is due to internal errors
          // FIXME: perhaps we should show a notification, at least when the error is not 401
          //notification.error({ message: '系统提示', description: '请求用户信息失败，请重试！' });
          await store.dispatch('Logout'); // clears the access token, which might have expired
          next({ path: '/user/login', query: { redirect: to.fullPath } });
        }
      } else {
        //store.commit(INC_PING_VER)
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，如果进入的页面是login页面并且当前是OAuth2app环境，就进入OAuth2登录页面
      if (to.path === '/user/login' && isOAuth2AppEnv()) {
        next({path: OAUTH2_LOGIN_PAGE_PATH})
      } else {
        // 在免登录白名单，直接进入
        next()
      }
      //NProgress.done()
    } else {
      // 如果当前是在OAuth2APP环境，就跳转到OAuth2登录页面
      let path = isOAuth2AppEnv() ? OAUTH2_LOGIN_PAGE_PATH : '/user/login'
      next({ path: path, query: { redirect: to.fullPath } })
      //NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
  store.commit(INC_PING_VER) // force token validity check
})
