import type { Res, SetupRsp } from "@/api/gdsdx/baseApis";
import { DspInfoMap, DspInfoMapByNameL, DspSvcInfoMap, extractResData, makeDspNameL, SocInfoMap } from "@/utils/gdsdx/gdsdxUtils";
import { axios } from "@/utils/request";
import type { Module } from "vuex";
import { INC_PING_VER, SET_DSP_INFO_MAP, SET_DSP_INFO_MAP_BY_NAMEL, SET_DSP_SVC_INFO_MAP, SET_SOC_INFO_MAP, TOGGLE_SHOW_DEBUG_OPTS } from "../mutation-types";
import type { GdsdxState, RootState } from "../state-types";

const state0: GdsdxState = { showDebugOpts: false, socInfoMap: undefined, dspInfoMap: undefined, dspInfoMapByNameL: undefined, dspSvcInfoMap: undefined, pingVer: 0 };
export default {
  state: () => state0, // must be a plain object (now we declare it as a function as recommended in <https://v3.vuex.vuejs.org/guide/modules.html#module-reuse>)
  mutations: { // cannot be asynchronous; mutator names are defined as constants in mutation-types.js in order to avoid typos.  Also, according to the type definition, each mutation can only have at most one parameter other than state
    [TOGGLE_SHOW_DEBUG_OPTS] (state: GdsdxState, value: boolean) { state.showDebugOpts = value; },
    [SET_SOC_INFO_MAP] (state: GdsdxState, socInfoMap: SocInfoMap) { state.socInfoMap = socInfoMap; },
    [SET_DSP_INFO_MAP] (state: GdsdxState, dspInfoMap: DspInfoMap) { state.dspInfoMap = dspInfoMap; },
    [SET_DSP_INFO_MAP_BY_NAMEL] (state: GdsdxState, dspInfoMapByNameL: DspInfoMapByNameL) { state.dspInfoMapByNameL = dspInfoMapByNameL; },
    [SET_DSP_SVC_INFO_MAP] (state: GdsdxState, dspSvcInfoMap: DspSvcInfoMap) { state.dspSvcInfoMap = dspSvcInfoMap; },
    [INC_PING_VER] (state: GdsdxState) {
      const newPingVer = ++state.pingVer;  console.debug("INC_PING_VER: %d", newPingVer);
    }
  },
  actions: { // can be asynchronous
    async FetchSetup({ commit }) { // not allowing interruption for now
      const rsp = extractResData((await axios.get('biz/x/setup/getSetup')) as Res<SetupRsp>);
      // It might be cleaner to use Map or null-prototype objects, but I don't know whether Vue's reactivity mechanism can handle those.
      // Seems that even if Vue reactivity can handle null-prototype objects, it will add extra properties (e.g. __ob__) into it.
      // In any case, the index is a number (coerced into a string), so it won't collide with built-in object properties.
      const socInfoMap: SocInfoMap = {};  rsp.socs.forEach(soc => socInfoMap[soc.socCode] = soc);
      commit(SET_SOC_INFO_MAP, socInfoMap);
      const dspInfoMap: DspInfoMap = {}, dspInfoMapByNameL: DspInfoMapByNameL = {};
      rsp.dsps.forEach(dsp => { dspInfoMap[dsp.dspId] = dsp;  dspInfoMapByNameL[makeDspNameL(dsp.dspName)] = dsp; });
      commit(SET_DSP_INFO_MAP, dspInfoMap);  commit(SET_DSP_INFO_MAP_BY_NAMEL, dspInfoMapByNameL);
      const dspSvcInfoMap: DspSvcInfoMap = {};  rsp.dspSvcs.forEach(svc => dspSvcInfoMap[svc.svcId] = svc);
      commit(SET_DSP_SVC_INFO_MAP, dspSvcInfoMap);
    }
  }
} as Module<GdsdxState,RootState>;