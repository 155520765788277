import { AccFilterMode, AccFilterResult, DspAssetIdSpec, DspAssetIdV, EditInfoV, LocalDateRange, SocWorkcode, SocWorkcodeSpec, StatusRspBase, StatusRspWithPreview, TerriSpecSpec, TerriSpecV, TimeRange } from "./baseApis";

export interface ParseKsFileNameRsp {
  fsocCode: number;
  fDspId: number;
  fDspName: string;
  svcId: number; // can be -1
  tAsD: string;
}
export interface ImportFileRsp {
  fId: number;
  fv: number;
}
export interface GetImportStatusRsp extends StatusRspBase {
  shouldImport: boolean;
  outErrs: boolean;
  jfv: number;
}

export interface ImpFileListV {
  flId: number;
  flSid: string;
  forceName?: string | null;
  socCode: number; // can be -1
}
export interface ListFlsRsp {
  fls: ImpFileListV[];
}

export interface ImportStatSheetV {
  sheetName: string;
  nOk: number;
  nConflict: number;
  nError: number;
}
export interface ImportStatV {
  sheetStats: ImportStatSheetV[];
}
export interface ImportStatTotalsV {
  nOk: number;
  nConflict: number;
  nError: number;
}

export interface ImpFileSheetV {
  sheetName: string;
}
export interface ImpFileStaticV {
  fileName: string;
  storePathS: string;
  tUpload: number;
  fsocCode: number;
  fDspId: number;
  shouldImport: boolean;
  stat?: ImportStatV | null;
}
export interface ImpFileV {
  fId: number;
  type: number;
  tAsD: string;
  svcId?: number | null;
  isEnabled0: boolean;
  tImport: number;
  cfv: number;
  mfv: number;
  ifv: number;
  statTotals?: ImportStatTotalsV;
  extS?: ImpFileStaticV | null;
  sheets: ImpFileSheetV[];
}
export interface ListFilesInFlRsp {
  files: ImpFileV[];
}
export interface GetFileInfoRsp {
  file: ImpFileV;
}

export interface ImpWriterV {
  name: string;
  isni?: string | null;
  ipin?: string | null;
}
export type ImpWriterSpec = {
  name: string;
  isni: string | undefined;
  ipin: string | undefined;
}

export const enum RightType { SYNC = "SYNC", MECH = "MECH", PERF = "PERF", LYRIC = "LYRIC" };
export interface ImpOwnerShareV {
  rightType: RightType;
  sharePerc: number;
  terriSpec?: TerriSpecV | null;
}
export type ImpOwnerShareSpec = {
  rightType: RightType;
  sharePerc: number;
  terriSpec: TerriSpecSpec | undefined;
}

export interface ImpOwnerPubV {
  pubName: string;
  shareEnts?: ImpOwnerShareV[] | null;
}
export type ImpOwnerPubSpec = {
  pubName: string;
  shareEnts: ImpOwnerShareSpec[];
}

export const enum MatchPolicy { ALL_COUNTRIES = 1 }
export interface ImpMatchV {
  // NOTE: Remember to update updEditedMatch() when fields are added here
  rId: number;
  isDeleted?: boolean | null;
  ei?: EditInfoV | null;
  fId: number;
  swc: SocWorkcode;
  iswc?: string | null;
  isrcs?: string[] | null;
  rDspId0?: number | null;
  das?: DspAssetIdV[] | null;
  title?: string | null;
  altTitles?: string[] | null;
  writers: ImpWriterV[];
  mp?: MatchPolicy | null;
  pubs: ImpOwnerPubV[];
  oinc?: boolean | null;
}
export type ImpMatchSpec = { // regularized a bit (e.g. some optional arrays are made non-optional) for convenience, at the cost of slightly more verbose JSON passed to the server
  rId: number;
  isDeleted: boolean;
  swc: SocWorkcodeSpec;
  iswc: string | undefined;
  isrcs: string[];
  rDspId0: number | undefined;
  das: DspAssetIdSpec[];
  title: string | undefined;
  altTitles: string[];
  writers: ImpWriterSpec[];
  mp: MatchPolicy | undefined;
  pubs: ImpOwnerPubSpec[];
  oinc: boolean | undefined;
}
export type EditReq = {
  fId: number;
  matchSpec: ImpMatchSpec;
}
export interface EditRsp {
  match: ImpMatchV;
}

export interface ImpMatchQFV extends ImpMatchV {
  flId: number;
  flSid: string;
  fileName: string;
  fv: number;
  sheetName: string;
  rowNo: number;
  asocAfRes?: AccFilterResult | null;
  rsocAfRes?: AccFilterResult | null;
  flSocCode: number;
}
export interface ListMatchesInSheetRsp {
  matches: ImpMatchQFV[];
}

// A const enum has their numeric (or string) values inlined at use sites during TypeScript compilation (similar to static final constants in Java), so the numbers should be kept stable.
export const enum AssetSpecType { InlineAssetIds = 1, InlineIsrcs = 2, File = 3 };
export type AssetSpec = {
  asType: AssetSpecType;
  dspId: number;
  assetIdsStr?: string;
  isrcsStr?: string;
  upId?: string;
  upFileName?: string;
};

export const enum WorkcodeSpecType { InlineWorkcodes = 1, InlineIswcs = 2, File = 3 };
export type WorkcodeSpec = { // used to be called WorkcodeSpecReq
  wcsType: WorkcodeSpecType;
  fsocCode: number; // can be -1
  wcsStr?: string;
  iswcsStr?: string;
  upId?: string;
  upFileName?: string;
};

export const enum TitleSpecType { InlineTitles = 1, File = 2 };
export type TitleSpec = { // used to be called TitleSpecReq
  tsType: TitleSpecType;
  incAltTitles: boolean;
  titles?: string;
  upId?: string;
  upFileName?: string;
};

export const enum AuthorSpecType { InlineAuthorNames = 1, File = 2 };
export type AuthorSpec = { // used to be called AuthorSpecReq
  ausType: AuthorSpecType;
  writers?: string;
  upId?: string;
  upFileName?: string;
};

export type ExtraMatchConds = { // corresponds to ExtraMatchCondsSpec in Java code
  onlySocCode: number | undefined;
  onlyDspId: number | undefined;
  onlySvcIds: number[] | undefined;
  tUploadRange: TimeRange | undefined;
  tAsRange: LocalDateRange | undefined;
}

export const enum SearchOpType { SRCH = 1, RECV = 2 } // for search options that can be used in both Receive and Search operations
export const enum ExportSrcType { SRCH = 1, RECV_QUERIES = 2, RECV_QUERIES_M = 3, RECV_QUERIES_M_R = 4, RECV_WORKS = 5, RECV_WORKS_NR = 6 }
export const enum ExportFmtType { V_YACT = 1, EX_YACT = 2, GACT = 3, MWI_DL_CSV = 4, CCR = 5, CCID_XLSX = 6 }

export type CCROptsSpec = {
  rightType: RightType;
  tcode2: string;
};
export type CCIDOptsSpec = {
  tcode2: string;
  mechPerc: number;
}
export type ExportOutSpec = {
  srcType: ExportSrcType;
  fmtType: ExportFmtType;
  allowMultiRowsPerMatch: boolean;
  ccrOpts: CCROptsSpec | undefined;
  ccidOpts: CCIDOptsSpec | undefined;
  maxConScore: number | undefined;
};
export interface ExportOutV {
  iout: number;
  fileName: string;
  srcType: ExportSrcType;
  fmtType: ExportFmtType;
  allowMultiRowsPerMatch: boolean;
  maxConScore: number | undefined;
}

export type JobOptsSpec = {
  shouldNotify: boolean;
  shouldNotifyEmail: boolean;
  forceJobDesc?: string | null; // used to be called jobDesc
};

export interface RecvReq extends AssetSpec {
  rsocCode: number;
  skipIswcNetFetch: boolean;
  iswcCacheAgeMs?: number;
  useMwiRtf?: boolean;
}
export const enum RecvOrigin { NONE = 0, SSOC = 1, DERIVED = 2 }
export interface RecvResultV {
  iRes: number;
  da?: DspAssetIdV | null;
  isrc?: string | null;
  rId?: number | null;
  fId?: number | null;
  flId?: number | null;
  flSid?: string | null;
  fileName?: string | null;
  swc?: SocWorkcode | null;
  iswc?: string | null;
  iswcOrigin?: RecvOrigin | null;
  rswc?: SocWorkcode | null;
  rwcOrigin?: RecvOrigin | null;
  rwcScore?: number | null;
  ssocTitle?: string | null;
  iswcNetTitle?: string | null;
  altTitles?: string[] | null;
  writers?: ImpWriterV[] | null;
  mp?: MatchPolicy | null;
  pubs?: ImpOwnerPubV[] | null;
}
export interface RecvByAssetsRsp {
  ress: RecvResultV[];
}
export interface RecvBgRsp {
  qId: number;
}

export interface MwiRtfUserV {
  socCode: number;
  userMail: string;
}
export const enum RecvMrFlags { NEED_CWR = 1 }
export interface RecvMrOptsV {
  mru: MwiRtfUserV;
  flags: number;
}
export interface RecvMrResultV {
  isOk?: boolean | null;
  pkgId?: number | null;
  hasCwr: boolean;
}
export interface GetRecvStatusRsp extends StatusRspWithPreview {
  rsocCode: number;
  skipIswcNetFetch?: boolean | null;
  iswcDtUpdateMin?: number | null;
  mro?: RecvMrOptsV | null;
  outs: ExportOutV[];
  mrRes?: RecvMrResultV | null;
}
export const enum RecvOutType { QUERIES = 1, QUERIES_MATCHED = 2, WORKS = 3, MISSING_WORKS_CSV = 4, MR_STATUS = 5, MR_CWR = 6 };

export type SearchOpts = {
  includeDeleted?: boolean; // currently ignored in Receive operations
  xcond?: string; // JSON-ified from ExtraMatchConds
  outs?: string; // JSON-ified from ExportOutSpec[]
  jobOpts?: string; // JSON-ified from JobOptsSpec
  asocFilterMode?: AccFilterMode;
  rsocCode: number; // actually optional, but we always specify it explicitly
  rsocFilterMode?: AccFilterMode;
}

export interface SearchByAssetsReq extends AssetSpec, SearchOpts {}
export interface SearchByWorkcodesReq extends WorkcodeSpec, SearchOpts {}
export interface SearchByTitlesReq extends TitleSpec, SearchOpts {}
export interface SearchByAuthorsReq extends AuthorSpec, SearchOpts {}
export interface SearchRsp {
  matches: ImpMatchQFV[];
}
export interface SearchBgRsp {
  qId: number;
}
export interface GetSearchStatusRsp extends StatusRspWithPreview {
  needPreview: boolean; // if we have never requested a preview, we should not ask the user to wait for one
  /** @deprecated Use `outs` instead */ needExportYACT: boolean;
  outs: ExportOutV[];
}